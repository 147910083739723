* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.mainImage {
  height: 250px;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid blue; */
}
.mainImage img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.h1Container {
  background-color: rgb(168, 167, 167);
  text-align: center;
  font-family: "Italianno", cursive;
  font-size: xx-large;
  color: rgb(255, 77, 0);
  letter-spacing: 2px;
  padding-top: 25px;
  text-shadow: 2px 0 rgb(0, 0, 0);
}

/* .foodGroup {
  border: 5px solid rgb(0, 0, 0);
} */

.generatorButtonGroup {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: rgb(168, 167, 167);
  align-items: center;
  padding: 15px 0 15px 0;
}

.generatorButtonGroup > button {
  border-radius: 5px;
  width: 200px;

  font-weight: bold;
  padding: 3px 0 3px 0;
}

.printButton {
  position: fixed;
  bottom: 10px;
  left: 10px;
  border-radius: 5px;
  padding: 15px 0 15px 0;
  text-align: center;
  width: 50px;
  box-shadow: 1px 1px 5px 3px rgba(35, 34, 34, 0.181);
}

.generatorButtonGroup > button:hover {
  background-color: rgb(247, 226, 211);
  color: black;
  transform: scale(1.1);
  transition: all 333ms;
}
/* .App {
  display: flex;
  flex-direction: row;
}
.App > div {
  border: 3px dotted blue;
  flex: 4 1 auto;
}

.App > div:last-child {
  flex: 1 1 auto;
} */

/* .meal:hover {
  background-color: rgb(242, 167, 110);
  transform: scale(1.1);
  transition: all 333ms;
} */

/* .maingroup {
  border: rgb(242, 156, 90) 3px solid;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: auto;
} */
/* .maingroup > div {
  border: 4px red dotted;
  flex: 1 1 auto;
} */

/* .maingroup > div:last-child {
  flex: 1 1 auto;
  border: 4px blue dotted;
} */
.maingroup .foodgroups {
  /* border: 5px solid green; */
  /* width: 60%; */
  height: 40vh;
  overflow-x: auto;
}

.shoplistcontainer-container {
  /* width: 40%; */
  height: 40vw;
}
@media (max-width: 600px) {
  .h1Container {
    font-size: 150%;
  }
  /* .maingroup {
    flex-direction: column-reverse;
  } */
  .maingroup .foodgroups,
  .maingroup .shoplistcontainer-container {
    width: 100%;
  }
}
