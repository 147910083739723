.ShopListContainer {
  /* border: 5px red solid; */
  background-color: rgb(197, 181, 115);
  height: 100%;
  padding: 0 15px;
}

.IngContainer {
  width: 100%;
  /* background-color: rgb(206, 195, 150); */
  /* border: 5px solid blue; */
  font-size: 12px;
  font-family: "Lato";
}
.QuantityCounter > button,
.QuantityCounter > div {
  /* border: 3px red solid; */
  /* width: 25%; */
  height: 30px;
  border-radius: 5px;
  flex: 1 1 auto;
}
.QuantityCounter > div {
  line-height: 30px;
  text-align: center;
}

.ShopListItem {
  /* border: 5px rgb(196, 38, 21) dotted; */
  width: 100%;
  display: inline-flex;
  flex-wrap: nowrap;
  border-bottom: 1px beige solid;
  padding: 10px;
  font-size: small;
}
.ShopListItem > h1 {
  /* border: 3px purple solid; */
  font-size: 14px;
  width: 66%;
}
.QuantityCounter {
  /* border: 3px greenyellow inset; */
  width: 33%;
  display: inline-flex;
  flex-wrap: nowrap;
}

.ShopListContainer > h3 {
  font-size: 18px;
  font-family: "Raleway", "Times New Roman", Times, serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px 0;
}
