.meal {
  margin-top: 15px;
  width: 12vw;
  /* min-width: 80px; */
  font-family: "Lato";
  font-weight: 300;
  overflow-wrap: break-word;
}
.foodGroup > h2 {
  color: rgb(0, 0, 0);
  font-family: "Raleway";
  font-size: large;
  letter-spacing: 1px;
  background-color: rgb(168, 167, 167);
  text-align: center;
  padding: 10px 0 15px 0;
}

.weekdays {
  font-family: "Lato";
}

.weekdays {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgb(242, 198, 164);
  min-height: 100px;
  width: 100%;
  text-align: center;
  padding-top: 15px;
  overflow-x: auto;
}

.day-container {
  flex: 1 1 auto;
  margin-bottom: 10px;
  border-right: 2px solid rgb(242, 156, 90);
  font-weight: bold;
  position: relative;
  padding-bottom: 35px;
}
.day-container:last-child {
  border-right: none;
}
.lockGroup {
  position: absolute;
  bottom: 0;
  left: 40%;
}
